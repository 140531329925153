<template>
  <WithQuery :base-filters="filterByReportStatus">
    <div slot-scope="{ filters }">
      <ReportsListView
        :view-title="`Viewing Dispatcher Reports`"
        :items-per-page="100"
        :filters="filters"
        :query-params="sortByDateInViewMode"
      />
    </div>
  </WithQuery>
</template>

<script>
import * as appConfig from '@src/app.config'
import ReportsListView from '@components/ReportsListView/ReportsListView'
import WithQuery from '@components/DataProviders/WithQuery'
import invertStatusFilters from '@utils/invertStatusFilters'

import { HumanFields, ReportStatuses } from '@constants/knack'

import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'DispatcherReportsListView',
  components: { WithQuery, ReportsListView },
  metaInfo: {
    title: 'Dispatcher Reports',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      sortByDateInViewMode: {
        sort_field: HumanFields.DAILY_REPORT.REPORT_DATE,
        sort_order: 'asc',
        view_mode: true,
        view_name: 'dispatcher_reports',
        ReportStatuses,
      },
    }
  },
  computed: {
    ...routeState(['query']),
    ...mapState(['user']),
    filterByReportStatus() {
      let acceptedStatuses = [ReportStatuses.SCHEDULED, ReportStatuses.SETUP]
      let knackKeyField = HumanFields.DAILY_REPORT.REPORT_STATUS
      return invertStatusFilters(
        ReportStatuses,
        acceptedStatuses,
        knackKeyField
      )
    }, // filterByReportStatus
  },
  methods: {},
}
</script>
